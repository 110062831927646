import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import { readableLabel, getFieldId, getVariableById } from "@/helpers/utils";
import isEmpty from "lodash/isEmpty";

import { useFormElement } from "@/helpers/composables/use-form-element";

const CONDITION = {
  field: {
    type: null,
    value: null,
  },
  clause: null,
  target: {
    type: null,
    value: null,
  },
};

const ACTION = {
  do: null,
  field: {
    type: null,
    value: null,
    values: [],
  },
  to: {
    type: null,
    value: null,
  },
};

export const RULE = {
  conditions: [{ ...CONDITION }],
  conjunction: "AND",
  action: { ...ACTION },
  isHidden: false,
};

const CONJUNCTIONS = ["AND", "OR"];

const NUMBER_CLAUSES = [
  "is_equal_to",
  "is_not_equal_to",
  "is_less_than",
  "is_greater_than",
  "is_less_than_or_equal_to",
  "is_greater_than_or_equal_to",
];

const TEXT_CLAUSES = [
  "is_equal_to",
  "is_not_equal_to",
  "contains",
  "does_not_contain",
  "is_empty",
  "is_filled",
];

const CHECKBOX_CLAUSES = [
  "is_equal_to",
  "is_not_equal_to",
  "is_empty",
  "is_filled",
  "contains",
  "does_not_contain",
];
const RADIO_CLAUSES = [
  "is_equal_to",
  "is_not_equal_to",
  "is_empty",
  "is_filled",
];

export const CALCULATION_ACTIONS = {
  add: "add",
  subtract: "subtract",
  multiply: "multiply",
  divide: "divide",
};

export const TEXT_VARIABLE_ACTIONS = {
  replace: "replace",
};

const LOGICAL_ACTIONS = {
  skip_to_page_on_next_button: "skip_to_page_on_next_button",
  hide_field: "hide_field",
  show_field: "show_field",
  redirect_to: "redirect_to",
  show_different_thank_you_page: "show_different_thank_you_page",
};

export const ACTIONS = {
  ...LOGICAL_ACTIONS,
  ...CALCULATION_ACTIONS,
  ...TEXT_VARIABLE_ACTIONS,
};

const MULTI_PAGE_FORM_ACTIONS = [
  ACTIONS.skip_to_page_on_next_button,
  ACTIONS.hide_field,
  ACTIONS.show_field,
  ACTIONS.show_different_thank_you_page,
  ...Object.values(CALCULATION_ACTIONS),
  ...Object.values(TEXT_VARIABLE_ACTIONS),
];

const SINGLE_PAGE_FORM_ACTIONS = [
  ACTIONS.hide_field,
  ACTIONS.show_field,
  ACTIONS.show_different_thank_you_page,
  ...Object.values(CALCULATION_ACTIONS),
  ...Object.values(TEXT_VARIABLE_ACTIONS),
];

const CHOICE_TYPES = [
  "radio",
  "dropdown",
  "multiple-checkbox",
  "picture-checkbox",
  "matrix",
];

export const getFormConditionActions = (multiPage) => {
  return multiPage
    ? [...MULTI_PAGE_FORM_ACTIONS]
    : [...SINGLE_PAGE_FORM_ACTIONS];
};

const ruleMixins = {
  props: {
    elements: {
      type: Array,
      required: true,
    },
    rules: {
      type: Array,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: [],
    };
  },
  computed: {
    inputFields: {
      get() {
        return this.elements.filter(
          (el) => el.canBeRuleConditionSource && !el.componentFromRepeatField,
        );
      },
    },
    fieldsLength() {
      return this.inputFields.length;
    },
    conjunctions() {
      return [...CONJUNCTIONS];
    },
    getThankYouPages() {
      return this.pages
        .filter((page) => page.type === "thank-you")
        .map((page) => ({
          label: page.name,
          id: page.id,
        }));
    },
    isMultiPage() {
      return this.pages.filter((page) => page.type !== "thank-you").length > 1;
    },
    ruleFields() {
      const { flattenFields } = this.getRuleFields(true);
      return [...flattenFields, ...this.getVariablesData(true)];
    },
    aiPayload() {
      const { flattenFields } = this.getRuleFields();
      const fields = this.inputFields.map((field) => {
        return {
          id: field.id,
          label: readableLabel(field.label) || field.id,
          page: field.page,
        };
      });

      return {
        fields,
        conditionFields: flattenFields,
        variables: this.getVariablesData(),
        pages: this.getPagesData(),
      };
    },
  },
  methods: {
    toggleAccordian(index) {
      this.isExpanded = this.isExpanded.map((item, idx) => {
        if (idx === index) {
          return !item;
        }
        return item;
      });
    },
    humanize(str) {
      return startCase(str);
    },
    // NOTE: making this into a method as in future there will be multiple conditions and cannot be computed
    conditionClauses(condition) {
      const fieldId = getFieldId(condition.field.value);
      // Clauses for quiz Score
      if (fieldId === "Quiz Score") {
        return NUMBER_CLAUSES.map((clause) => ({
          id: clause,
          label: this.humanize(clause),
        }));
      }

      const variable = getVariableById(this.variables, fieldId);
      if (variable) {
        const variableClauses =
          variable.dataType === "text" ? TEXT_CLAUSES : NUMBER_CLAUSES;
        return variableClauses.map((clause) => ({
          id: clause,
          label: this.humanize(clause),
        }));
      }

      // NOTE: searching by label for now this needs to be better
      const field =
        this.elements.find((el) => {
          if (el.children) {
            return this.nestedFields(el.children).some(
              (child) => child.id === fieldId,
            );
          } else {
            return el.id === fieldId;
          }
        }) || {};

      let clauses = [...(field.clauses || [])];
      // remove contains'and 'does_not_contain' options in case of single select
      if (field.type === "picture-checkbox" && !field.multipleSelect) {
        clauses = RADIO_CLAUSES;
      }
      // If field type is matrix
      if (field.type === "matrix") {
        if (field.inputType === "checkbox") {
          clauses = CHECKBOX_CLAUSES;
        } else if (field.inputType === "radio") {
          clauses = RADIO_CLAUSES;
        }
      }

      return clauses.map((clause) => ({
        id: clause,
        label: this.humanize(clause),
      }));
    },
    conditionActions(page) {
      if (page) {
        return [
          {
            label: this.humanize(ACTIONS.skip_to_page_on_next_button),
            id: ACTIONS.skip_to_page_on_next_button,
          },
        ];
      }

      let actions = getFormConditionActions(this.isMultiPage);
      return this.groupActionsByCategory(actions);
    },
    groupActionsByCategory(actions) {
      const actionCategories = {
        Logics: LOGICAL_ACTIONS,
        Calculations: CALCULATION_ACTIONS,
        Text: TEXT_VARIABLE_ACTIONS,
      };

      const groupedActions = [];

      Object.keys(actionCategories).forEach((category) => {
        const categoryObject = { label: category, options: [] };
        const actionSet = actionCategories[category];

        actions.forEach((action) => {
          if (actionSet[action]) {
            categoryObject.options.push({
              label: this.humanize(action),
              id: action,
              icon: `action-${action}`,
            });
          }
        });

        if (categoryObject.options.length) {
          groupedActions.push(categoryObject);
        }
      });

      return groupedActions;
    },
    isTargetDisabled(clause) {
      if (clause === "is_filled" || clause === "is_empty") {
        return true;
      }
      return false;
    },
    nestedFields(children) {
      const fields = [];
      children
        .filter((child) => child.active)
        .forEach((child) => {
          if (child.children) {
            fields.push(...this.nestedFields(child.children));
          } else {
            fields.push({ id: child.id, label: child.label });
          }
        });
      return fields;
    },
    actionFields(ruledElementId) {
      let ruledElementIndex = -1;
      if (ruledElementId) {
        ruledElementIndex = this.inputFields.findIndex((f) => {
          if (!isEmpty(f.children)) {
            return this.matchChildById(ruledElementId, f.children);
          }
          return f.id === ruledElementId;
        });
      }
      const fields = this.inputFields.filter((f, i) => i > ruledElementIndex);
      return fields.map((field) => {
        return {
          id: field.id,
          label: readableLabel(field.label) || field.id,
        };
      });
    },
    targetValueOptions(condition) {
      const fieldId = getFieldId(condition.field.value);
      const field = this.elements.find((el) => el.id === fieldId) || {};
      let { options } = field;

      if (field.type === "matrix") {
        options = [...field.columns];
      }

      if (CHOICE_TYPES.includes(field.type)) {
        return options?.map((option) => ({
          id: option.id,
          label: option.label,
        }));
      }
    },
    deleteRule(ruleIndex) {
      this.rules.splice(ruleIndex, 1);
    },
    cloneRule(ruleIndex) {
      const clonedRule = cloneDeep(this.rules[ruleIndex]);
      this.rules.splice(ruleIndex + 1, 0, clonedRule);
    },
    clearActionTargets(rule) {
      const defaultAction = cloneDeep(ACTION);
      defaultAction.do = rule.action.do;
      Object.assign(rule, { action: defaultAction });
    },
    addRule(defaultPageRule = false) {
      const rule = { ...RULE };
      if (defaultPageRule) {
        rule.page = null;
        rule.action.do = ACTIONS.skip_to_page_on_next_button;
      }
      this.rules.push(cloneDeep(rule));
    },
    addAiRules(rules) {
      this.rules.push(...rules);
    },
    addCondition(rule) {
      rule.conditions = [...rule.conditions, cloneDeep(CONDITION)];
    },
    removeCondition(rule, conditionIndex) {
      const conditions = rule.conditions;
      conditions.splice(conditionIndex, 1);
      rule.conditions = conditions;
    },
    getPages(ruledElementid) {
      let pageIndex = -1;
      if (ruledElementid) {
        const ruledElement = this.inputFields.find((f) => {
          if (!isEmpty(f.children)) {
            return this.matchChildById(ruledElementid, f.children);
          }
          return f.id === ruledElementid;
        });
        pageIndex = this.pages.findIndex(
          (page) => page.id === ruledElement?.page,
        );
      }
      const formPages = this.pages
        .filter((page, index) => index > pageIndex && page.type !== "thank-you")
        .map((page) => ({
          label: page.name,
          id: page.id,
        }));

      return formPages.concat(...this.getThankYouPages);
    },
    getVariablesByType(type) {
      return this.variables
        .filter((variable) => variable.dataType === type)
        .map((variable) => ({
          label: variable.name,
          id: variable.uuid,
        }));
    },
    matchChildById(id, children) {
      children.forEach((child) => {
        if (!isEmpty(child.children)) {
          return this.matchChildById(id, child.children);
        }
        return child.id === id;
      });
    },
    processChildFields(field, extraProps = {}) {
      return this.nestedFields(field.children).map((child) => ({
        id: child.id,
        label: readableLabel(child.label) || child.id,
        page: field.page,
        parentId: field.id,
        ...extraProps,
      }));
    },

    processMatrixField(field, extraProps = {}) {
      if (field.inputType !== "checkbox" && field.inputType !== "radio") {
        return [];
      }

      return field.rows.map((row) => ({
        id: `${field.id}_${row.id}`,
        label: readableLabel(row.label),
        page: field.page,
        parentId: field.id,
        options: field.columns ? [...field.columns] : undefined,
        ...extraProps,
      }));
    },

    processRegularField(field, extraProps = {}) {
      const baseField = {
        id: field.id,
        label: readableLabel(field.label) || field.id,
        page: field.page,
        ...extraProps,
      };

      if (CHOICE_TYPES.includes(field.type)) {
        return {
          ...baseField,
          options: field.options,
        };
      }

      return baseField;
    },
    processParentField(field) {
      return {
        id: field.id,
        label: readableLabel(field.label) || field.id,
        page: field.page,
      };
    },
    getRuleFields(allowExtraProps = false) {
      const fields = this.inputFields.filter(
        (field) => field.category === "input" && field.type !== "ranking",
      );
      const flattenFields = [];
      const parentFields = [];

      fields.forEach((field) => {
        const extraProps = allowExtraProps
          ? {
              icon: field.icon,
              group: field.group,
            }
          : {};
        if (field.children) {
          flattenFields.push(...this.processChildFields(field, extraProps));
          parentFields.push(this.processParentField(field));
        } else if (field.type === "matrix") {
          flattenFields.push(...this.processMatrixField(field, extraProps));
          parentFields.push(this.processParentField(field));
        } else {
          flattenFields.push(this.processRegularField(field, extraProps));
        }
      });

      return { flattenFields, parentFields };
    },
    getVariablesData(includePrefix = false) {
      return this.variables.map((variable) => ({
        id: includePrefix ? `variable_${variable.uuid}` : variable.uuid,
        label: variable.name,
        type: variable.dataType,
        ...(includePrefix && {
          icon: "variable-x",
          group: "variable",
        }),
      }));
    },
    getPagesData() {
      return this.pages.map((page) => ({
        id: page.id,
        label: page.name,
        type: page.type,
      }));
    },
  },
  watch: {
    fieldsLength: {
      immediate: true,
      handler() {
        this.isExpanded = Array(this.fieldsLength).fill(false);
      },
    },
  },
};

export default ruleMixins;
